import { windSources, windRoseData } from "./chartTestData";
import {
  PolarChart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  ValueAxis,
  Margin,
  Export,
} from "devextreme-react/polar-chart";

const styles = {
  chartHeight: {
    height: 600,
  },
};

export default function TestPage() {
  return (
    <div style={styles.chartHeight}>
      <div id="chart-demo">
        <PolarChart
          id="radarChart"
          palette="Soft"
          dataSource={windRoseData}
          title="Роза ветров"
        >
          <CommonSeriesSettings type="stackedbar" />
          {windSources.map((item) => (
            <Series
              key={item.value}
              valueField={item.value}
              name={item.name}
            />
          ))}
          <Margin
            bottom={50}
            left={100}
          />
          <ArgumentAxis
            discreteAxisDivisionMode="crossLabels"
            firstPointOnStartAngle={true}
          />
          <ValueAxis valueMarginsEnabled={false} />
          <Export enabled={true} />
        </PolarChart>
      </div>
    </div>
  );
}
