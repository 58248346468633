import { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import css from "./style.module.scss";
import {
  EnvironmentOutlined,
  LoginOutlined,
  UserOutlined,
} from "@ant-design/icons";
import globalStore from "@globalStore";
import { observer } from "mobx-react-lite";
import LogoutOutlined from "@ant-design/icons/es/icons/LogoutOutlined";
import { LoginButton } from "./components/LoginButton";
import { ContextSelect } from "./components/ContextSelect";

const { subjectStore, authStore, projectStore } = globalStore;

const Navbar = observer(() => {
  const [keys, setKeys] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setKeys([location.pathname]);
  }, [location]);

  const navigation = [
    {
      label: <Link to="/">Главная</Link>,
      key: "/",
    },
    {
      label: <Link to="/data-view/demonstration">Рабочая область</Link>,
      key: "/data-view/demonstration",
    },
    // {
    //   label: "Население",
    //   key: "/models-population",
    //   children: [
    //     {
    //       label: (
    //         <Link to="/models-population/population/demonstration">
    //           Территориальное расселение
    //         </Link>
    //       ),
    //       key: "/models-population/population/demonstration",
    //     },
    //     {
    //       label: (
    //         <Link to="/models-population/human-society/demonstration">
    //           Ценностная модель населения
    //         </Link>
    //       ),
    //       key: "/models-population/human-society/demonstration",
    //     },
    //     // {
    //     //   label: <Link to="/models-population/life-model/demonstration">Модель жизни</Link>,
    //     //   key: "/models-population/life-model/demonstration",
    //     // },
    //   ],
    // },
    {
      type: "divider",
    },
    // {
    //   label: "Контекст",
    //   key: "context",
    //   children: [
    //     {
    //       label: (
    //         <Link to="/context/demonstration/pages/context">
    //           Сводка обеспеченности территорий
    //         </Link>
    //       ),
    //       key: "/context/demonstration/pages/context",
    //     },
    //     {
    //       label: (
    //         <Link to="/context/demonstration/pages/cityValues">
    //           Городские ценности
    //         </Link>
    //       ),
    //       key: "/context/demonstration/pages/cityValues",
    //     },
    //     {
    //       label: (
    //         <Link to="/context/demonstration/pages/urbanQuality">
    //           Индексы качества городской среды
    //         </Link>
    //       ),
    //       key: "/context/demonstration/pages/urbanQuality",
    //     },
    //     {
    //       label: (
    //         <Link to="/context/demonstration/pages/collocations">
    //           Матрица коллокаций городских сервисов
    //         </Link>
    //       ),
    //       key: "/context/demonstration/pages/collocations",
    //     },
    //   ],
    // },
    {
      label: <Link to="/applied-services">Сервисы</Link>,
      key: "/applied-services",
      // children: [
      //   {
      //     label: (
      //       <Link to="/context/demonstration/pages/collocations">
      //         Матрица коллокаций городских сервисов
      //       </Link>
      //     ),
      //     key: "/context/demonstration/pages/collocations",
      //   },
      //   {
      //     label: (
      //       <Link to="/models-population/population/demonstration">
      //         Территориальное расселение
      //       </Link>
      //     ),
      //     key: "/models-population/population/demonstration",
      //   },
      //   {
      //     label: (
      //       <Link to="/models-population/human-society/demonstration">
      //         Ценностная модель населения
      //       </Link>
      //     ),
      //     key: "/models-population/human-society/demonstration",
      //   },
      //   {
      //     label: (
      //       <Link to="/applied-services/greenery">
      //         Формирование рекомендуемых композиций видов растений
      //       </Link>
      //     ),
      //     key: "applied-services/greenery",
      //   },
      //   {
      //     label: (
      //       <Link to="/applied-services/accidents">
      //         Аналитика сообщений о городских происшествиях
      //       </Link>
      //     ),
      //     key: "applied-services/accidents",
      //   },
      //   {
      //     type: "divider",
      //   },
      //   {
      //     label: (
      //       <a
      //         href={`${process.env.REACT_APP_REF_MARKETPLACE}`}
      //         target="_blank"
      //       >
      //         Маркет-плейс решений умного города
      //       </a>
      //     ),
      //     key: `${process.env.REACT_APP_REF_MARKETPLACE}`,
      //   },
      //   {
      //     label: (
      //       <a
      //         href={`${process.env.REACT_APP_REF_THEMATIC_ROUTES}`}
      //         target="_blank"
      //         rel="noreferrer"
      //       >
      //         Тематические маршруты по Санкт-Петербургу
      //       </a>
      //     ),
      //     key: `${process.env.REACT_APP_REF_THEMATIC_ROUTES}`,
      //   },
      //   {
      //     label: (
      //       <a
      //         href={`${process.env.REACT_APP_REF_GEO_QUESTIONARE}`}
      //         target="_blank"
      //         rel="noreferrer"
      //       >
      //         Платформа геолоцированных социологических опросов
      //       </a>
      //     ),
      //     key: `${process.env.REACT_APP_REF_GEO_QUESTIONARE}`,
      //   },
      //   {
      //     label: (
      //       <a
      //         href={`${process.env.REACT_APP_REF_GIS_DATA}`}
      //         target="_blank"
      //         rel="noreferrer"
      //       >
      //         Сервис экспорта и обработки ГИС данных
      //       </a>
      //     ),
      //     key: `${process.env.REACT_APP_REF_GIS_DATA}`,
      //   },
      //   {
      //     label: (
      //       <a
      //         href={`${process.env.REACT_APP_REF_LABOR_RESOURCES}`}
      //         target="_blank"
      //         rel="noreferrer"
      //       >
      //         Трудовые ресурсы
      //       </a>
      //     ),
      //     key: `${process.env.REACT_APP_REF_LABOR_RESOURCES}`,
      //   },
      // ],
    },
    // {
    // label: <Link to="/workSources/demonstration">Трудовые ресурсы</Link>,
    // key: '/workSources/demonstration',
    // },
    {
      label: <Link to="/about">О платформе</Link>,
      key: "/about",
    },
    subjectStore.currentCity && subjectStore.currentCity.name === "Севастополь"
      ? {
          label: <Link to="/stations-crud">Станции</Link>,
          key: "/stations-crud",
        }
      : null,
    {
      label: <ContextSelect />,
      key: "/context-select",
      icon: <EnvironmentOutlined />,
    },
    {
      label: <LoginButton />,
      key: "/account/info",
      icon: authStore.isLogged ? <UserOutlined /> : <LoginOutlined />,
      children: authStore.isLogged && [
        {
          label: <Link to="/account/projects">Проекты</Link>,
          key: "/account/projects",
        },
        {
          label: "Сценарии",
          key: "/account/scenarios",
          children:
            projectStore.isUserProjectsFetched &&
            projectStore.userProjects.map((project) => ({
              label: (
                <Link to={`/account/projects/${project.id}/demonstration`}>
                  {project.name}
                </Link>
              ),
              key: `/account/projects/${project.id}/demonstration`,
            })),
        },
        {
          label: <Link to="/account/layers">Слои</Link>,
          key: "/account/layers",
        },
        {
          label: <a onClick={() => authStore.logout()}>Выйти</a>,
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];

  return (
    <Menu
      className={css.container}
      mode="horizontal"
      items={navigation}
      selectedKeys={keys}
    />
  );
});

export default Navbar;
