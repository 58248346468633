import React from "react";
import MainFooter from "../landing/components/mainFooter/MainFooter"

const Footer = () => {
  return (
      <MainFooter/>
    // <Layout.Footer style={{ backgroundColor: "#074A74" }}>
    //   <div className={css.container}>
    //     <a href="https://actcognitive.org/" target="_blank">
    //       <img src="https://i.imgur.com/O4vPwgu.png" alt="логотип НЦКР" className={css.logo} />
    //     </a>
    //   </div>
    // </Layout.Footer>
  );
};

export default Footer;
