import { action, makeAutoObservable } from "mobx";
import axios from "axios";

class SocialStore {
  rootStore = null;
  parentGroups = [];
  livingSituations = [];

  get isFetched() {
    return (
      this.parentGroups.length > 0 &&
      this.livingSituations.length > 0 &&
      this.livingSituations.reduce((acc, curr) => acc && curr.isFetched, true)
    );
  }

  get socialGroups() {
    return this.parentGroups.map((parentGroup) => parentGroup.childGroups).flat();
  }

  getChildGroupById(id) {
    const childGroup = this.socialGroups.find((group) => group.id === parseInt(id));
    return childGroup ? childGroup : null;
  }

  getChildGroupByName(name) {
    return this.socialGroups.find((group) => group.name === name);
  }

  getLivingSituationById(id) {
    return this.livingSituations.find((livingSituation) => livingSituation.id === parseInt(id));
  }

  fetchSocialGroupsHierarchy(){
     axios.get(`${process.env.REACT_APP_MAIN_API_NEW}/api/list/social_groups/hierarchy`).then(
        action(({ data: parentGroups }) => {
          this.parentGroups = parentGroups.map((parentGroup) => new ParentGroup(parentGroup));
        })
    )
  }

  fetchLivingSituations (){
     axios
        .get(`${process.env.REACT_APP_MAIN_API_NEW}/api/list/living_situations`)
        .then(
            action(
                ({ data: livingSituations }) =>
                    (this.livingSituations = livingSituations
                        .map((livingSituation) => new LivingSituation(livingSituation, this))
                        .sort((a, b) => a.name.localeCompare(b.name)))
            )
        )
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.fetchSocialGroupsHierarchy();
    this.fetchLivingSituations();
  }
}

class ParentGroup {
  id = null;
  name = null;
  code = null;
  childGroups = [];

  constructor(parentGroupJson) {
    this.id = parentGroupJson.id;
    this.name = parentGroupJson.name;
    this.code = parentGroupJson.code;
    this.childGroups = parentGroupJson.children.map(
      (childGroup) => new ChildGroup(this, childGroup)
    );
  }
}

class ChildGroup {
  id = null;
  name = null;
  code = null;
  parentGroup = null;

  constructor(parentGroup, childGroupJson) {
    this.id = childGroupJson.id;
    this.name = childGroupJson.name;
    this.code = childGroupJson.code;
    this.parentGroup = parentGroup;
  }
}

class LivingSituation {
  socialStore = null;
  id = null;
  name = null;
  _evaluations = null;

  get isFetched() {
    return this._evaluations !== null;
  }

  get serviceTypes() {
    return Object.keys(this._evaluations).map((name) =>
      this.socialStore.rootStore.serviceStore.getServiceTypeByName(name)
    );
  }

  getServiceTypeEvaluation(serviceType) {
    return this._evaluations[serviceType.name];
  }

  constructor(livingSituation, socialStore) {
    makeAutoObservable(this);
    this.socialStore = socialStore;
    this.id = livingSituation.id;
    this.name = livingSituation.name;
    axios
      .get(`${process.env.REACT_APP_MAIN_API_NEW}/api/maintenance/city_service_types`, {
        params: { living_situation: livingSituation.name },
      })
      .then(
        action(({ data: evaluations }) => {
          let tmp = {};
          evaluations.forEach(({ name, evaluation }) => (tmp[name] = evaluation));
          this._evaluations = tmp;
        })
      );
  }
}

export default SocialStore;
