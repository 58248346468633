import { message } from "antd";
import { autorun, makeAutoObservable, action, when, runInAction } from "mobx";
import axios from "axios";

export default class LayerStore {
  globalStore = null;
  layers = [];
  isInitialized = false;
  isUserLayersFetched = false;

  get publicLayers() {
    return this.layers
      .filter((layer) => layer.isPublic)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get userLayers() {
    const user = this.globalStore.authStore.user;
    if (!this.globalStore.authStore.isLogged) return [];
    return this.layers
      .filter((layer) => layer.userId === user.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getLayerById(id) {
    return this.layers.find((layer) => layer.id === id);
  }

  get cityPublicLayers() {
    return this.publicLayers
      .filter((layer) => layer.city === this.globalStore.subjectStore.city)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get projectPublicLayers() {
    return this.publicLayers
      .filter((layer) => layer.projectId === this.globalStore.projectStore.currentProject)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get cityUserLayers() {
    return this.userLayers
      .filter((layer) => layer.city === this.globalStore.subjectStore.city)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get projectUserLayers() {
    return this.userLayers
      .filter((layer) => layer.project === this.globalStore.projectStore.currentProject)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  deleteLayer(deletingLayer) {
    axios
      .delete(`${process.env.REACT_APP_LAYERS_API}/api/user_layers/${deletingLayer.id}/`, {
        headers: {
          Authorization: this.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        action(() => {
          this.layers = this.layers.filter((layer) => layer !== deletingLayer);
          message.success("Слой был успешно удален");
        }),
        (error) => message.error(`Ошибка при удалении слоя: ${error.message}`)
      );
  }

  addLayer({ isPublic, file, city, project, styleJson = null, filtersJson = null, name = null }) {
    file.text().then((text) => {
      let geojson = JSON.parse(text);
      geojson.features.push({
        type: "Feature",
        geometry: { type: "Point", coordinates: [0, 0] },
        properties: {
          styleFeature: true,
          style: styleJson,
          filters: filtersJson,
        },
      });
      const geojsonFile = new File(
        [JSON.stringify(geojson)],
        name ? `${name}.geojson` : file.name,
        {
          type: "text/plain",
        }
      );
      let formData = new FormData();
      formData.append("cities_id", city.id);
      if (project) formData.append("user_projects_id", project.id);

      formData.append("file", geojsonFile);
      formData.append("public", isPublic);
      axios
        .post(`${process.env.REACT_APP_LAYERS_API}/api/user_layers/`, formData, {
          headers: {
            Authorization: this.globalStore.authStore.user.authorizationHeader,
          },
        })
        .then(
          action(({ data }) => {
            const layer = new Layer(data, this);
            this.layers.push(layer);
            return message.success(`Слой был успешно добавлен`);
          }),
          action((error) => message.error(`Ошибка при добавлении слоя: ${error.message}`))
        );
    });
  }

  async fetchUserLayers() {
    return axios
      .get(`${process.env.REACT_APP_LAYERS_API}/api/user_layers/`, {
        headers: {
          Authorization: this.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        action(({ data: userLayers }) => {
          this.layers.push(
            ...userLayers
              .filter((userLayer) => !this.layers.find((layer) => layer.id === userLayer._id))
              .map((userLayer) => new Layer(userLayer, this))
          );
          this.isUserLayersFetched = true;
        })
      );
  }

  async fetchPublicLayers() {
    return axios
      .get(`${process.env.REACT_APP_LAYERS_API}/api/public_users_layers`)
      .then(
        action(
          ({ data: publicLayers }) =>
            (this.layers = publicLayers.map((publicLayer) => new Layer(publicLayer, this)))
        )
      );
  }

  constructor(globalStore) {
    makeAutoObservable(this);
    this.globalStore = globalStore;

    when(
      () => globalStore.projectStore.isInitialized,
      () => {
        let promise = this.fetchPublicLayers();
        if (globalStore.authStore.isLogged) promise = promise.then(() => this.fetchUserLayers());
        promise.then(
          action(() => {
            this.isInitialized = true; //flag that everything is ready for the work
          })
        );
      }
    );
    autorun(() => {
      if (!this.isInitialized) return;
      if (!globalStore.authStore.isLogged)
        runInAction(() => {
          //если незалогинен, оставляем только публичные слои
          this.layers = this.layers.filter((layer) => layer.isPublic);
          this.isUserLayersFetched = false;
        });
      else if (globalStore.projectStore.isUserProjectsFetched && !this.isUserLayersFetched)
        this.fetchUserLayers(); //если залогинен, грузим слои юзера
    });
  }
}

class Layer {
  layerStore = null;
  id = null;
  name = null;
  createdAt = null;
  updatedAt = null;
  isPublic = null;
  _cityId = null;
  _projectId = null;
  userId = null;

  get city() {
    return this.layerStore.globalStore.subjectStore.getCityById(this._cityId);
  }

  get project() {
    return this.layerStore.globalStore.projectStore.getProjectById(this._projectId);
  }

  async fetch() {
    const { authStore } = this.layerStore.globalStore;
    return axios
      .get(
        `${process.env.REACT_APP_LAYERS_API}/api/${
          this.isPublic ? "public_users_layers" : "user_layers"
        }/${this.id}`,
        {
          headers: {
            Authorization: authStore.isLogged ? authStore.user.authorizationHeader : null,
          },
        }
      )
      .then(({ data: geojson }) => geojson);
  }

  updateFile(file) {
    let formData = new FormData();
    formData.append("file", file);
    return axios
      .put(`${process.env.REACT_APP_LAYERS_API}/api/user_layers/${this.id}`, formData, {
        headers: {
          Authorization: this.layerStore.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        () => message.success("Файл слоя был успешно обновлен"),
        (error) => message.error(`Ошибка при обновлении файла слоя: ${error.message}`)
      );
  }

  updateInfo({ name, isPublic, city, project = null }) {
    return axios
      .patch(
        `${process.env.REACT_APP_LAYERS_API}/api/user_layers/${this.id}`,
        {
          name: name,
          public: isPublic,
          cities_id: city.id,
          user_projects_id: project ? project.id : null,
        },
        {
          headers: {
            Authorization: this.layerStore.globalStore.authStore.user.authorizationHeader,
          },
        }
      )
      .then(
        action(({ data }) => {
          message.success("Информация слоя была успешно обновлена");
          this.name = name;
          this.isPublic = isPublic;
          this._cityId = city.id;
          if (project) this._projectId = project.id;
        }),
        (error) => message.error(`Ошибка при обновлении информации слоя: ${error.message}`)
      );
  }

  constructor(layerJson, layerStore) {
    makeAutoObservable(this);
    this.layerStore = layerStore;
    this.id = layerJson._id;
    this.name = layerJson.name;
    this.createdAt = new Date(layerJson.create_at);
    this.updatedAt = new Date(layerJson.update_at);
    this.isPublic = layerJson.public;
    this._projectId = layerJson.user_projects_id;
    this._cityId = layerJson.cities_id;
    this.userId = layerJson.user_id;
  }
}
