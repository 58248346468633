/* eslint-disable import/first */
import React, { useEffect, useState } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import LoadingSpinner from "./components/shared/Loading";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";
import Navbar from "./components/navbar";
import Footer from "./components/shared/Footer";
import "@globalStore";
import { Layout, FloatButton, Button, ConfigProvider } from "antd";
import { UpOutlined } from "@ant-design/icons";
import "mapbox-gl/dist/mapbox-gl.css";
import "./../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import TestPage from "./components/test/TestPage";
import axios from "axios";

const Main = React.lazy(() => import("./components/index/mainHome"));
const ModelsRouter = React.lazy(() => import("./components/models/Router"));
//const LoyalityRouter = React.lazy(() => import("./components/loyality/Router"));
const ContextInterfaceRouter = React.lazy(() =>
  import("./components/context/Router")
);
const DataView = React.lazy(() => import("./components/dataView"));
const WorkSources = React.lazy(() => import("./components/workSources"));
const AboutUsRouter = React.lazy(() =>
  import("./components/common/AboutUs/Router")
);
const AppliedServiceRouter = React.lazy(() =>
  import("./components/services/Router")
);
const Account = React.lazy(() => import("./components/account/Router"));
const StationsCrud = React.lazy(() => import("./components/stationsCrud"));
const NotFound = React.lazy(() => import("./components/not-found"));
const ErrorPage = React.lazy(() => import("./components/error/ErrorPage"));

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = () => {
  let location = useLocation();
  const appTitle = "Платформа цифровой урбанистики";

  const [showFooter, setShowFooter] = useState(true);

  const pages = [
    {
      path: "/",
      title: "Главная страница",
      component: <Main />,
    },
    {
      path: "/test",
      title: "Test Page",
      component: <TestPage />,
    },
    {
      path: "/models-population/*",
      title: "Население",
      component: <ModelsRouter />,
    },
    {
      path: "/context/*",
      title: "Контекст",
      component: <ContextInterfaceRouter />,
    },
    // {
    //   path: "/loyality/*",
    //   title: "Благополучие",
    //   component: <LoyalityRouter />,
    // },
    {
      path: "/data-view/demonstration",
      title: "Рабочая область",
      component: <DataView />,
    },
    {
      path: "/applied-services/*",
      title: "Сервисы",
      component: <AppliedServiceRouter />,
    },
    {
      path: "/workSources/demonstration",
      title: "Трудовые ресурсы",
      component: <WorkSources />,
    },
    {
      path: "/about/*",
      title: "О платформе",
      component: <AboutUsRouter />,
    },
    {
      path: "/account/*",
      title: "Личный кабинет",
      component: <Account />,
    },
    {
      path: "/stations-crud/*",
      title: "Управление станциями",
      component: <StationsCrud />,
    },
    {
      path: "/error-page",
      title: "Ошибка",
      component: <ErrorPage />,
    },
  ];

  useEffect(() => {
    setShowFooter(!location.pathname.includes("demonstration"));

    let _page = null;
    const path = location.pathname.split("/");

    pages.forEach((item) => {
      const itemPath = item.path.replaceAll("/", "").replaceAll("*", "");

      if (path.find((str) => str === itemPath)) {
        _page = item;
      }
    });
    document.title = _page ? `${_page.title} | ${appTitle}` : appTitle;
  }, [location.pathname]);

  useEffect(() => {
    // const axiosInterceptor = axios.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     if (error.response && error.response.status === 500) {
    //       window.location = '/error-page';
    //     }
    //     return Promise.reject(error);
    //   }
    // );
    // return () => {
    //   axios.interceptors.response.eject(axiosInterceptor);
    // };
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: "#ff0048",
          // colorLink: "#ff0048",
          fontFamily: `Golos Text`,
        },
      }}
    >
      <Layout style={{ minHeight: "100vh", width: "100%" }}>
        <ScrollToTop />
        <FloatButton.BackTop>
          <Button
            type="primary"
            shape="circle"
            icon={<UpOutlined />}
            size="large"
            style={{ right: " -4px", bottom: "-24px" }}
          />
        </FloatButton.BackTop>
        <Layout.Header
          style={{
            background: "white",
            position: "fixed",
            zIndex: 1000,
            width: "100%",
            boxShadow: "0 0 4px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Navbar />
        </Layout.Header>
        <Layout style={{ marginTop: 64, alignItems: "stretch", width: "100%" }}>
          <React.Suspense fallback={<LoadingSpinner />}>
            <Routes>
              {pages.map((page) => (
                <Route
                  title={page.title}
                  element={page.component}
                  key={page.path}
                  path={page.path}
                />
              ))}
              <Route
                path={"/not-found"}
                title={"404"}
                element={<NotFound />}
                key={"not-found"}
              />
              <Route
                path={"*"}
                title={""}
                element={<Navigate to="/not-found" />}
                key={"default"}
              />
            </Routes>
          </React.Suspense>
        </Layout>
        {showFooter && <Footer />}
      </Layout>
    </ConfigProvider>
  );
};
export default App;
