import axios from "axios";
import { message } from "antd";
import { action, makeAutoObservable, toJS } from "mobx";

export class Scenario {
  id = null;
  name = null;
  description = null;
  project = null;
  serviceContainers = [];

  get isFetched() {
    return this.serviceContainers.reduce((acc, curr) => acc && curr.isFetched, true);
  }

  async fetchData() {
    return Promise.allSettled(
      this.serviceContainers.map((serviceContainer) => serviceContainer.fetchData())
    );
  }

  getServiceContainerByServiceTypeCode(serviceTypeCode) {
    return this.serviceContainers.find(
      (serviceContainer) => serviceContainer.serviceType.code === serviceTypeCode
    );
  }

  save() {
    axios.patch(`${process.env.REACT_APP_LAYERS_API}/api/scenarios/${this.id}`, this.json).then(
      action(({ data }) => {
        message.success("Сценарий был успешно сохранен");
      }),
      () => {
        message.error("Не удалось сохранить изменения");
      }
    );
  }

  getProvisionHouses(serviceTypes, valuationType) {
    const serviceContainer = this.getServiceContainerByServiceTypeCode("houses");
    let geojson = { type: "FeatureCollection", features: [] };
    geojson.features.push(
      ...serviceContainer.added.map((feature) => {
        let tmp = {};
        Object.assign(tmp, feature);
        serviceTypes.forEach(
          (serviceType) =>
            (tmp.properties[`${serviceType.code}_service_demand_value_${valuationType}`] =
              feature.properties.population)
        );
        return tmp;
      })
    );
    geojson.features.push(
      ...serviceContainer.deletedGeojson.features.map((feature) => {
        let tmp = {};
        Object.assign(tmp, feature);
        serviceTypes.forEach(
          (serviceType) =>
            (tmp.properties[`${serviceType.code}_service_demand_value_${valuationType}`] = 0)
        );
        return tmp;
      })
    );
    geojson.features.forEach((feature, index) => {
      if ("block" in feature.properties) {
        feature.properties.functional_object_id = feature.properties.id;
      } else {
        feature.id = 2000000 + index;
        feature.properties.functional_object_id = feature.id;
        feature.properties.id = feature.id;
      }
    });
    geojson.features.forEach((feature) => {
      let defaultProperties = {};
      serviceTypes.forEach((serviceType) => {
        defaultProperties[`${serviceType.code}_provison_value`] = 0;
        defaultProperties[`${serviceType.code}_supplyed_demands_within`] = 0;
        defaultProperties[`${serviceType.code}_supplyed_demands_without`] = 0;
      });
      feature.properties = {
        ...feature.properties,
        ...defaultProperties,
      };
    });
    return geojson.features.length > 0 ? geojson : null;
  }

  getProvisionServices(serviceTypes) {
    const serviceContainers = serviceTypes.map((serviceType) =>
      this.getServiceContainerByServiceTypeCode(serviceType.code)
    );
    let geojson = { type: "FeatureCollection", features: [] };
    serviceContainers.forEach((serviceContainer) => {
      let features = [];
      features.push(...serviceContainer.added);
      features.push(
        ...serviceContainer.deletedGeojson.features.map((feature) => {
          let tmp = {};
          Object.assign(tmp, feature);
          tmp.properties.capacity = 0;
          return tmp;
        })
      );
      features.forEach(
        (feature) =>
          (feature.properties = {
            city_service_type: serviceContainer.serviceType.name,
            city_service_type_id: serviceContainer.serviceType.id,
            service_code: serviceContainer.serviceType.code,
            ...feature.properties,
          })
      );
      geojson.features.push(...features);
    });
    geojson.features.forEach((feature, index) => {
      if (!("block" in feature.properties)) {
        feature.id = 2000000 + index;
        feature.properties.id = feature.id;
      }
    });
    geojson.features.forEach(
      (feature, index) =>
        (feature.properties = {
          ...feature.properties,
          capacity_left: 0,
          carried_capacity_within: 0,
          carried_capacity_without: 0,
        })
    );
    return geojson.features.length > 0 ? geojson : null;
  }

  get json() {
    let params = {};
    this.serviceContainers.forEach(
      (serviceContainer) => (params[serviceContainer.serviceType.code] = serviceContainer.json)
    );
    return {
      name: this.name,
      description: this.description,
      user_project_id: this.project.id,
      params: params,
    };
  }

  constructor({ name, description, params, _id }, project) {
    makeAutoObservable(this);
    this.id = _id;
    this.name = name;
    this.description = description;
    this.project = project;
    this.serviceContainers = Object.entries(params).map(
      ([serviceCode, serviceContainerJson]) =>
        new ServiceContainer(
          this,
          project.projectStore.globalStore.serviceStore.getServiceTypeByCode(serviceCode),
          serviceContainerJson
        )
    );
  }
}

class ServiceContainer {
  scenario = null;
  serviceType = null;
  _data = null;
  added = [];
  changed = [];
  deleted = [];

  get json() {
    return {
      added: toJS(this.added),
      changed: toJS(this.changed),
      deleted: toJS(this.deleted),
    };
  }

  get newId() {
    return this.added.length > 0 ? this.added[this.added.length - 1].properties.id + 1 : 1;
  }

  addFeature(geometry, properties) {
    this.added.push({
      type: "Feature",
      geometry: geometry,
      properties: { id: this.newId, ...properties },
    });
  }

  deleteFeature(feature) {
    this.deleted.push(feature.properties.id);
  }

  returnFeature(feature) {
    this.deleted = this.deleted.filter(
      (deletedFeatureId) => deletedFeatureId !== feature.properties.id
    );
    this.added = this.added.filter(
      (addedFeature) => addedFeature.properties.id !== feature.properties.id
    );
  }

  //all services from db
  get geojson() {
    return this._data;
  }

  //normalized for provision purposes
  get provisionGeojson() {
    let geojson = {
      type: "FeatureCollection",
      name: "geojson",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      features: [...this.addedGeojson.features],
    };
    geojson.features.push(
      ...this.addedGeojson.features.map((feature, index) => {
        let tmp = {};
        Object.assign(tmp, feature);
        tmp.properties.id = index + 1;
        return tmp;
      })
    );
    geojson.features.push(
      ...this.deletedGeojson.features.map((feature) => {
        let tmp = {};
        Object.assign(tmp, feature);
        feature.properties.capacity = 0;
        return tmp;
      })
    );
    return geojson;
  }

  //data without deleted services
  get defaultGeojson() {
    return {
      type: "FeatureCollection",
      features: this._data.features.filter((feature) => {
        if (this.deleted.indexOf(feature.properties.id) >= 0) return false;
        return true;
      }),
    };
  }

  //only added features
  get addedGeojson() {
    return {
      type: "FeatureCollection",
      features: this.added.slice(),
    };
  }

  get deletedGeojson() {
    return {
      type: "FeatureCollection",
      features: this._data.features.filter(
        (feature) => this.deleted.indexOf(feature.properties.id) >= 0
      ),
    };
  }

  get resultGeojson() {
    return {
      type: "FeatureCollection",
      features: [
        ...this._data.features.filter((feature) => {
          if (this.deleted.indexOf(feature.properties.id) >= 0) return false;
          return true;
        }),
        ...this.added,
      ],
    };
  }

  get isFetched() {
    return !!this._data;
  }

  async fetchData() {
    const { authStore } = this.scenario.project.projectStore.globalStore;
    return axios
      .post(
        `${process.env.REACT_APP_MAIN_API_NEW}/api/city/${this.scenario.project.city.name}/inside_geometry/services`,
        this.scenario.project.geojson.geometry,
        {
          headers: {
            Authorization: authStore.isLogged ? authStore.user.authorizationHeader : null,
          },
          params: {
            city_service_type: this.serviceType.name,
            geometryAsCenter: false,
          },
        }
      )
      .then(action(({ data }) => (this._data = data)));
  }

  constructor(scenario, serviceType, serviceContainerJson) {
    makeAutoObservable(this);
    this.scenario = scenario;
    this.serviceType = serviceType;
    this.added = serviceContainerJson.added;
    this.deleted = serviceContainerJson.deleted;
    this.changed = serviceContainerJson.changed;
  }
}
