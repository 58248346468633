import { makeAutoObservable, when } from "mobx";

export default class ContextSelectStore {
  subjectStore = null;
  projectStore = null;
  region = null;
  city = null;
  project = null;

  get isFetched() {
    return this.region !== null || this.city !== null || this.project !== null;
  }

  get regions() {
    return this.subjectStore.regions.map((region) => ({ name: region }));
  }

  get cities() {
    return this.subjectStore.getCitiesByRegion(this.region);
  }

  get projects() {
    return this.projectStore.getProjectsByCity(this.city);
  }

  changeRegion({ name }) {
    this.project = null;
    this.city = null;
    this.region = name;
  }
  changeCity(city) {
    this.project = null;
    this.city = city;
    this.region = city.region;
  }
  changeProject(project) {
    this.project = project;
    this.city = project.city;
    this.region = project.city.region;
  }

  resetChanges() {
    this.region = this.subjectStore.currentCity.region;
    this.city = this.subjectStore.currentCity;
    this.project = this.projectStore.currentProject;
  }

  confirmChanges() {
    this.projectStore.setCurrentProject(this.project);
    this.subjectStore.setCurrentCity(this.city);
  }

  constructor(subjectStore, projectStore) {
    makeAutoObservable(this);
    this.subjectStore = subjectStore;
    this.projectStore = projectStore;
    when(
      () => this.subjectStore.isInitialized && this.projectStore.isInitialized,
      () => this.resetChanges()
    );
  }
}
