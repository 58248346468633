import React, { useEffect, useMemo, useRef } from "react";
import { Modal, Row, Col, Space, Tag, Typography, Select } from "antd";
import globalStore from "@globalStore";
import { observer } from "mobx-react-lite";
import { EnvironmentOutlined } from "@ant-design/icons";
import ContextSelectStore from "./contextSelectStore";
import css from "./contextSelect.module.scss";
const { subjectStore, projectStore } = globalStore;
const store = new ContextSelectStore(subjectStore, projectStore);

const CustomList = ({ header, data, itemSelectHandler, selectedItem }) => {
  const containerRef = useRef();
  const refs = useMemo(() => data.map(() => React.createRef()), [data]);
  const dataSource = data.map((item, index) => ({
    ref: refs[index],
    className: `${css.item} ${selectedItem === item ? css.selected : null}`,
    item: item,
  }));

  const scrollToItem = (item) => {
    const { ref } = item;
    console.log(ref.current.offsetTop);
    containerRef.current.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop - ref.current.clientHeight,
      left: 0,
    });
  };

  useEffect(() => {
    const item = dataSource.find(({ item }) => item === selectedItem);
    if (item) scrollToItem(item);
  }, [selectedItem]);

  return data.length > 0 ? (
    <div>
      <Typography.Title level={5}>{header}</Typography.Title>
      <div
        ref={containerRef}
        className={css.list}
      >
        {dataSource.map(({ item, ref, className }) => (
          <div
            ref={ref}
            className={className}
          >
            {item.name}
            <a onClick={itemSelectHandler(item)}></a>
          </div>
        ))}{" "}
      </div>
    </div>
  ) : null;
};

const Content = observer(() => {
  return (
    <>
      <Row
        gutter={[15, 15]}
        style={{ marginTop: 15 }}
      >
        <Col span={24}>
          <Select
            style={{ width: "100%" }}
            showSearch
            allowClear
            onSelect={(e) => {
              const [type, value] = e.split("/");
              switch (type) {
                case "region":
                  store.changeRegion({ name: value });
                  break;
                case "city":
                  store.changeCity(subjectStore.getCityById(value));
                  break;
                case "project":
                  store.changeProject(projectStore.getProjectById(value));
                  break;
                default:
                  break;
              }
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                label: "Регионы",
                options: subjectStore.regions.map((region) => ({
                  value: `region/${region}`,
                  label: region,
                })),
              },
              {
                label: "Города",
                options: subjectStore.cities.map((city) => ({
                  value: `city/${city.id}`,
                  label: city.name,
                })),
              },
              {
                label: "Проекты",
                options: projectStore.projects.map((project) => ({
                  value: `project/${project.id}`,
                  label: project.name,
                })),
              },
            ]}
            placeholder="Поиск"
          />
        </Col>
        <Col span={24}>
          <Space
            style={{ height: "100%" }}
            align="center"
          >
            {subjectStore.publicCities.map((city) => (
              <Tag
                key={city.id}
                icon={<EnvironmentOutlined />}
              >
                <a onClick={() => store.changeCity(city)}>{city.name}</a>
              </Tag>
            ))}
          </Space>
        </Col>
        <Col span={8}>
          <CustomList
            header="Регион"
            data={store.regions}
            selectedItem={store.regions.find(
              (region) => region.name === store.region
            )}
            itemSelectHandler={(item) => () => store.changeRegion(item)}
          />
        </Col>
        <Col span={8}>
          <CustomList
            header="Город"
            data={store.cities}
            selectedItem={store.city}
            itemSelectHandler={(item) => () => store.changeCity(item)}
          />
        </Col>
        <Col span={8}>
          <CustomList
            header="Проект"
            data={store.projects}
            selectedItem={store.project}
            itemSelectHandler={(item) => () => store.changeProject(item)}
          />
        </Col>
      </Row>
    </>
  );
});

export const ContextSelect = observer(() => {
  return store.isFetched ? (
    <>
      <a
        onClick={() =>
          Modal.confirm({
            width: 600,
            centered: true,
            maskClosable: true,
            icon: <EnvironmentOutlined />,
            title: "Выбор города или проекта",
            okText: "Ок",
            cancelText: "Отмена",
            content: <Content />,
            afterClose: () => store.resetChanges(),
            onOk: () => store.confirmChanges(),
          })
        }
      >
        {projectStore.currentProject
          ? projectStore.currentProject.name
          : subjectStore.currentCity.name}
      </a>
    </>
  ) : null;
});
