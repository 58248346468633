export const windSources = [
  { value: "val1", name: "1.3-4 m/s" },
  { value: "val2", name: "4-8 m/s" },
  { value: "val3", name: "8-13 m/s" },
  { value: "val4", name: "13-19 m/s" },
  { value: "val5", name: "19-25 m/s" },
  { value: "val6", name: "25-32 m/s" },
  { value: "val7", name: "32-39 m/s" },
  { value: "val8", name: "39-47 m/s" },
];

export const windRoseData = [
  {
    arg: "С",
    val1: 2,
    val2: 10,
    val3: 6,
    val4: 3,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ССВ",
    val1: 0.1,
    val2: 0.6,
    val3: 0.1,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "СВ",
    val1: 0.3,
    val2: 0.8,
    val3: 0.1,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ВСВ",
    val1: 0.3,
    val2: 0.7,
    val3: 0.1,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "В",
    val1: 0.7,
    val2: 3.2,
    val3: 2.5,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ВЮВ",
    val1: 0.8,
    val2: 1.5,
    val3: 0.3,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ЮВ",
    val1: 0.3,
    val2: 1.3,
    val3: 0.4,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ЮЮВ",
    val1: 0.1,
    val2: 2.4,
    val3: 0.3,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "Ю",
    val1: 1.1,
    val2: 4.2,
    val3: 2.2,
    val4: 0,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ЮЮЗ",
    val1: 0.6,
    val2: 3.6,
    val3: 3.5,
    val4: 0.4,
    val5: 0.1,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ЮЗ",
    val1: 0.8,
    val2: 2.5,
    val3: 5,
    val4: 1.3,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ЗЮЗ",
    val1: 0.3,
    val2: 2.6,
    val3: 3.2,
    val4: 0.4,
    val5: 0,
    val6: 0,
    val7: 0.1,
    val8: 0,
  },
  {
    arg: "З",
    val1: 0.6,
    val2: 1.7,
    val3: 2.6,
    val4: 0.3,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ЗСЗ",
    val1: 0.7,
    val2: 2.5,
    val3: 3.1,
    val4: 0.3,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "СЗ",
    val1: 1,
    val2: 3.2,
    val3: 2.6,
    val4: 0.8,
    val5: 0.1,
    val6: 0,
    val7: 0,
    val8: 0,
  },
  {
    arg: "ССЗ",
    val1: 0.6,
    val2: 3.8,
    val3: 4.3,
    val4: 2.2,
    val5: 0,
    val6: 0,
    val7: 0,
    val8: 0,
  },
];
