import { action, makeAutoObservable } from "mobx";
import axios from "axios";
import { message } from "antd";
import jwtDecode from "jwt-decode";

const storageRefreshTokenName = "loyalCity.refreshToken";
const storageAccessTokenName = "loyalCity.accessToken";

class AuthStore {
  _globalStore = null;
  user = null;
  isLoggedIn = false;
  isInitialized = false; //если забрали данные из localStorage и провели первую проверку на логин или логаут
  scopes = [];

  get isLogged() {
    return this.user && this.user.isFetched;
  }

  async login(username, password) {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("grant_type", "password");
    return axios.post(`${process.env.REACT_APP_AUTH}/token/`, formData).then(
      action(({ data }) => {
        this.user = new User(
          { refreshToken: data.refresh_token, accessToken: data.access_token },
          this
        );
        this.setStorageTokens(data.refresh_token, data.access_token);
        this.isLoggedIn = true
      })
    );
  }

  async logout() {
    message.success("Вы успешно вышли из аккаунта");
    this.setStorageTokens("", "");
    this.user.clearRefreshTimer();
    this.isLoggedIn = false
    return (this.user = null);
  }

  async checkToken(accessToken) {
    let formData = new FormData();
    formData.append("token_type_hint", "access_token");
    formData.append("token", accessToken);
    return axios
      .post(`${process.env.REACT_APP_AUTH}/introspect/`, formData)
      .then(action(({ data }) => data.active));
  }

  async refreshTokens(refreshToken) {
    let formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", refreshToken);
    return axios.post(`${process.env.REACT_APP_AUTH}/refresh_token/`, formData).then(
      action(({ data }) => ({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
      }))
    ).catch((reason)=>{
      message.warning("Ошибка авторизации");
      console.log(reason)
    });
  }

  setStorageTokens(refreshToken, accessToken) {
    window.localStorage.setItem(storageRefreshTokenName, refreshToken);
    window.localStorage.setItem(storageAccessTokenName, accessToken);
  }

  getStorageTokens() {
    let storageRefreshToken = window.localStorage.getItem(storageRefreshTokenName);
    let storageAccessToken = window.localStorage.getItem(storageAccessTokenName);
    return { refreshToken: storageRefreshToken, accessToken: storageAccessToken };
  }

  constructor(globalStore) {
    makeAutoObservable(this);
    this._globalStore = globalStore;
    const { refreshToken, accessToken } = this.getStorageTokens();
    //console.log(refreshToken, accessToken)
    if (refreshToken && accessToken)
      this.checkToken(accessToken).then(
        action((active) => {
          if (active) {
            this.user = new User({ refreshToken, accessToken }, this);
            this.scopes = jwtDecode(accessToken).scopes
            this.isInitialized = true;
          } else
            this.refreshTokens(refreshToken).then(
              action(({ refreshToken, accessToken }) => {
                this.user = new User({ refreshToken, accessToken }, this);
                this.scopes = jwtDecode(accessToken).scopes
                this.isInitialized = true;
              }),
              action((e) => {
                message.warning("Истекло время авторизации");
                this.setStorageTokens("", "");
                this.scopes = [];
                this.isInitialized = true;
              })
            ).catch((reason)=>{
              message.warning("Ошибка во время авторизации");
              this.setStorageTokens("", "");
              this.scopes = [];
              console.log(reason)
            });
        })
      );
    else this.isInitialized = true;
  }
}

class User {
  _authStore = null;
  refreshToken = null;
  accessToken = null;
  refreshTimer = null;
  info = null; // {email, firstName, lastName}
  isSuperuser = false;

  get id() {
    return this.info.email;
  }

  get isFetched() {
    return this.info;
  }

  //remove refresh timer on logout
  clearRefreshTimer() {
    clearInterval(this.refreshTimer);
    console.log("refresh timer cleared");
  }

  //refresh timer for auto re-login in sec
  setRefreshTimer(interval) {
    this.refreshTimer = setInterval(
      () =>
        this._authStore.refreshTokens(this.refreshToken).then(
          action(({ accessToken, refreshToken }) => {
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
          })
        ),
      interval * 1000
    );
    console.log(`refresh timer set every ${interval / 60} min`);
  }

  get authorizationHeader() {
    return `Bearer ${this.accessToken}`;
  }

  constructor({ refreshToken, accessToken }, authStore) {
    makeAutoObservable(this);
    this.refreshToken = refreshToken;
    this.accessToken = accessToken;
    this._authStore = authStore;

    axios
      .get(`${process.env.REACT_APP_AUTH}/users/me/`, {
        headers: {
          Authorization: this.authorizationHeader,
        },
      })
      .then(
        action(({ data }) => {
          this.isSuperuser = data.is_superuser;
          this.info = {
            email: data.email,
            firstName: data.first_name,
            lastName: data.last_name,
          };
        })
      );
    this._authStore.refreshTokens(refreshToken).then(({ expiresIn }) => {
      this.setRefreshTimer(expiresIn / 2);
    });
  }
}

export default AuthStore;
