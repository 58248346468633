import { makeAutoObservable, when } from "mobx";

class AggregationContextStore {
  globalStore = null;
  isAvailable = false;

  get isInitialized() {
    return this.isAvailable;
  }


  constructor(globalStore) {
    makeAutoObservable(this);
    this.rootStore = globalStore;

    when(
        () => globalStore.subjectStore.isInitialized,
        ()=> this.isAvailable = true
    )
  }
}

export default AggregationContextStore;
