import { message } from "antd";
import axios from "axios";
import { action, autorun, makeAutoObservable, runInAction, when } from "mobx";
import { Scenario } from "./scenario";

export default class ProjectStore {
  globalStore = null;
  projects = [];
  currentProject = null;
  isInitialized = false;
  isUserProjectsFetched = false;

  get publicProjects() {
    return this.projects
      .filter((project) => project.isPublic)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get userProjects() {
    const user = this.globalStore.authStore.user;
    if (!this.globalStore.authStore.isLogged) return [];
    return this.projects
      .filter((project) => project.userId === user.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  setCurrentProject(project) {
    if (project === this.currentProject) return;
    if (project) {
      window.localStorage.setItem("loyalCity.currentProjectId", project.id);
      if (!project.isFetched) project.fetchData();
      if (!project.isScenariosFetched) project.fetchScenarios();
    } else {
      window.localStorage.setItem("loyalCity.currentProjectId", "");
    }
    this.currentProject = project;
  }

  getUserProjectById(id) {
    return this.userProjects.find((userProject) => userProject.id === id);
  }

  getPublicProjectById(id) {
    return this.publicProjects.find((publicProject) => publicProject.id === id);
  }

  getProjectById(id) {
    return this.projects.find((project) => project.id === id);
  }

  getUserProjectsByCity(city) {
    return this.userProjects.filter((userProject) => userProject.city === city);
  }

  getPublicProjectsByCity(city) {
    return this.publicProjects.filter((publicProject) => publicProject.city === city);
  }

  getProjectsByCity(city) {
    return this.projects.filter((project) => project.city === city);
  }

  addProject(name, description, isPublic, city, geojson) {
    let resultGeojson = {};
    Object.assign(resultGeojson, geojson);
    resultGeojson.properties = {
      name: name,
      cities_id: city.id,
      public: isPublic,
      description: description,
    };
    axios
      .post(`${process.env.REACT_APP_LAYERS_API}/api/user_projects`, resultGeojson, {
        headers: {
          Authorization: this.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        action(({ data }) => {
          const project = new Project(
            {
              _id: data._id,
              name: name,
              cities_id: city.id,
              public: isPublic,
              description: description,
              user_id: this.globalStore.authStore.user.id,
            },
            this
          );
          this.projects.push(project);
          return message.success(`Проект был успешно создан`);
        }),
        action((error) => message.error(`Ошибка при создании проекта: ${error.message}`))
      );
  }

  deleteProject(project) {
    axios
      .delete(`${process.env.REACT_APP_LAYERS_API}/api/user_projects/${project.id}/`, {
        headers: {
          Authorization: this.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        action(() => {
          this.projects = this.projects.filter((userProject) => userProject !== project);
          this.resetCurrentProject();
          message.success("Проект был успешно удален");
        }),
        (error) => message.error(`Ошибка при удалении проекта: ${error.message}`)
      );
  }

  async fetchUserProjects() {
    return axios
      .get(`${process.env.REACT_APP_LAYERS_API}/api/user_projects`, {
        headers: {
          Authorization: this.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        action(({ data: userProjects }) => {
          this.projects.push(
            ...userProjects
              .filter(
                (userProject) => !this.projects.find((project) => project.id === userProject._id)
              )
              .map((userProject) => new Project(userProject, this))
          );
          return (this.isUserProjectsFetched = true);
        })
      );
  }

  async fetchPublicProjects() {
    return axios
      .get(`${process.env.REACT_APP_LAYERS_API}/api/public_user_projects`)
      .then(
        action(
          ({ data: publicProjects }) =>
            (this.projects = publicProjects.map(
              (publicProject) => new Project(publicProject, this)
            ))
        )
      );
  }

  resetCurrentProject() {
    if (this.projects.indexOf(this.currentProject) < 0) this.setCurrentProject(null)
  }

  constructor(globalStore) {
    makeAutoObservable(this);
    this.globalStore = globalStore;
    when(
      () => globalStore.subjectStore.isInitialized,
      () => {
        let promise = this.fetchPublicProjects();
        if (globalStore.authStore.isLogged) promise = promise.then(() => this.fetchUserProjects());
        promise.then(
          action(() => {
            let storageProjectId = window.localStorage.getItem("loyalCity.currentProjectId");
            if (storageProjectId) {
              const storageProject = this.getProjectById(storageProjectId);
              if (storageProject) this.setCurrentProject(storageProject);
              else this.resetCurrentProject();
            } else this.resetCurrentProject();
            this.isInitialized = true; //flag that everything is ready for the work
          })
        );
      }
    );
    autorun(() => {
      if (!this.isInitialized) return;
      if (!globalStore.authStore.isLogged)
        runInAction(() => {
          //если незалогинен, оставляем только публичные проекты
          this.projects = this.projects.filter((project) => project.isPublic);
          this.resetCurrentProject();
          this.isUserProjectsFetched = false;
        });
      else if (globalStore.subjectStore.isUserCitiesFetched && !this.isUserProjectsFetched)
        this.fetchUserProjects(); //если залогинен, грузим проекты юзера
    });
  }
}

class Project {
  projectStore = null;
  scenarios = null;
  _data = null;
  id = null;
  name = null;
  _cityId = null;
  isPublic = null;
  description = null;
  userId = null;

  get isScenariosFetched() {
    return this.scenarios !== null;
  }

  get city() {
    return this.projectStore.globalStore.subjectStore.getCityById(this._cityId);
  }

  get coordinates() {
    return this._data.geometry.coordinates;
  }

  get isFetched() {
    return !!this._data;
  }

  get geojson() {
    return this._data;
  }

  getScenarioById(id) {
    return this.scenarios.find((scenario) => scenario.id === id);
  }

  updateGeojson(geojson) {
    return axios
      .put(`${process.env.REACT_APP_LAYERS_API}/api/user_projects/${this.id}`, geojson, {
        headers: {
          Authorization: this.projectStore.globalStore.authStore.user.authorizationHeader,
        },
      })
      .then(
        action(() => {
          this._data = geojson;
          message.success("Границы проекта были успешно обновлены");
        }),
        (error) => message.error(`Ошибка при обновлении границ проекта: ${error.message}`)
      );
  }

  updateProperties({ name, description, isPublic, city }) {
    return axios
      .patch(
        `${process.env.REACT_APP_LAYERS_API}/api/user_projects/${this.id}`,
        {
          name: name,
          description,
          public: isPublic,
          cities_id: this.city.id,
        },
        {
          headers: {
            Authorization: this.projectStore.globalStore.authStore.user.authorizationHeader,
          },
        }
      )
      .then(
        action(({ data }) => {
          message.success("Свойства проекта были успешно обновлены");
          Object.assign(this, { name, description, isPublic, cityId: city.id });
        }),
        (error) => message.error(`Ошибка при обновлении свойств проекта: ${error.message}`)
      );
  }

  async addScenario({ name, description }) {
    return axios
      .post(`${process.env.REACT_APP_LAYERS_API}/api/scenarios`, {
        user_project_id: this.id,
        name: name,
        description: description,
        params: {
          houses: {
            added: [],
            deleted: [],
            changed: [],
          },
          schools: {
            added: [],
            deleted: [],
            changed: [],
          },
          kindergartens: {
            added: [],
            deleted: [],
            changed: [],
          },
        },
      })
      .then(
        action(({ data }) => {
          message.success("Сценарий был успешно создан");
          let scenario = new Scenario(data, this);
          this.scenarios.push(scenario);
          return scenario;
        }),
        () => {
          message.error("Не удалось создать сценарий");
        }
      );
  }

  async deleteScenario(scenario) {
    console.log(scenario);
    return axios.delete(`${process.env.REACT_APP_LAYERS_API}/api/scenarios/${scenario.id}`).then(
      action(({ data }) => {
        message.success("Сценарий был успешно удален");
        const scenarios = this.scenarios.filter((sc) => sc !== scenario);
        this.scenarios = scenarios;
        return scenarios;
      }),
      () => {
        message.error("Не удалось удалить сценарий");
      }
    );
  }

  async fetchScenarios() {
    return axios
      .get(`${process.env.REACT_APP_LAYERS_API}/api/scenarios`, {
        params: {
          user_project_id: this.id,
        },
      })
      .then(
        action(({ data }) => {
          const scenarios = data.map((scenarioJson) => new Scenario(scenarioJson, this));
          this.scenarios = scenarios;
          return scenarios;
        })
      );
  }

  async fetchData() {
    const { authStore } = this.projectStore.globalStore;
    return axios
      .get(
        `${process.env.REACT_APP_LAYERS_API}/api/${
          this.isPublic ? "public_user_projects" : "user_projects"
        }/${this.id}`,
        {
          headers: {
            Authorization: authStore.isLogged ? authStore.user.authorizationHeader : null,
          },
        }
      )
      .then(
        action(({ data }) => {
          if (this.isPublic) this._data = data.feature;
          else this._data = data;
        })
      );
  }

  constructor(projectJson, projectStore) {
    makeAutoObservable(this);
    this.projectStore = projectStore;
    this.id = projectJson._id;
    this.name = projectJson.name;
    this.description = projectJson.description;
    this.isPublic = projectJson.public;
    this._cityId = projectJson.cities_id;
    this.userId = projectJson.user_id;
  }
}
