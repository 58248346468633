import { action, autorun, makeAutoObservable, runInAction, when } from "mobx";
import axios from "axios";

class ServiceStore {
  rootStore = null;
  infrastructures = [];
  isAvailableFetched = false;
  _availableServiceHierarchy = [];

  get isInitialized() {
    return this.infrastructures.length > 0;
  }

  get availableInfrastructures() {
    return this._availableServiceHierarchy.map((infrastructure) =>
      this.getInfrastructure(infrastructure.id)
    );
  }

  get availableCityFunctions() {
    return this._availableServiceHierarchy
      .map((infrastructure) => infrastructure.city_functions)
      .flat()
      .map((cityFunction) => this.getCityFunctionById(cityFunction.id));
  }

  get availableServiceTypes() {
    return this._availableServiceHierarchy
      .map((infrastructure) => infrastructure.city_functions)
      .flat(1)
      .map((cityFunction) => cityFunction.city_service_types)
      .flat(1)
      .map((serviceType) => this.getServiceTypeById(serviceType.id));
  }

  isInfrastructureAvailable(infrastructure) {
    return this.availableInfrastructures.indexOf(infrastructure) >= 0;
  }

  isCityFunctionAvailable(cityFunction) {
    return this.availableCityFunctions.indexOf(cityFunction) >= 0;
  }

  isServiceTypeAvailable(serviceType) {
    return this.availableServiceTypes.indexOf(serviceType) >= 0;
  }

  getInfrastructure(id) {
    let infrastructure = this.infrastructures.find((inf) => inf.id === parseInt(id));
    return infrastructure ? infrastructure : null;
  }

  getCityFunctionById(id) {
    return this.cityFunctions.find((cityFunction) => cityFunction.id === parseInt(id));
  }

  getServiceType(id) {
    let serviceType = this.serviceTypes.find((st) => st.id === parseInt(id));
    return serviceType ? serviceType : null;
  }

  getServiceTypeById(id) {
    let serviceType = this.serviceTypes.find((st) => st.id === parseInt(id));
    return serviceType ? serviceType : null;
  }

  getServiceTypeByCode(code) {
    let serviceType = this.serviceTypes.find((st) => st.code === code);
    return serviceType ? serviceType : null;
  }

  getServiceTypeByName(name) {
    return this.serviceTypes.find((st) => st.name === name);
  }

  get isFetched() {
    return this.infrastructures.length > 0 && this.isAvailableFetched;
  }

  get serviceTypes() {
    return this.infrastructures.map((infrastructure) => infrastructure.serviceTypes).flat();
  }

  get cityFunctions() {
    return this.infrastructures.map((infrastructure) => infrastructure.cityFunctions).flat();
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    axios
      .get(`${process.env.REACT_APP_MAIN_API_NEW}/api/list/city_infrastructure_types/hierarchy`)
      .then(
        action(
          ({ data: infrastructures }) =>
            (this.infrastructures = infrastructures
              .map((infrastructure) => new Infrastructure(infrastructure))
              .sort((a, b) => a.name.localeCompare(b.name)))
        )
      );
    autorun(() => {
      const city = rootStore.subjectStore.city;
      runInAction(() => (this._availableServiceHierarchy = []));
      this.isAvailableFetched = false;
      if (rootStore.subjectStore.city)
        axios
          .get(
            `${process.env.REACT_APP_MAIN_API_NEW}/api/list/city_infrastructure_types/hierarchy`,
            {
              headers: {
                Authorization: this.rootStore.authStore.isLogged
                  ? this.rootStore.authStore.user.authorizationHeader
                  : null,
              },
              params: {
                city: city.name,
              },
            }
          )
          .then(
            action(({ data }) => {
              this.isAvailableFetched = true;
              this._availableServiceHierarchy = data;
            })
          );
    });
  }
}

class Infrastructure {
  id = null;
  name = null;
  code = null;
  cityFunctions = [];

  get serviceTypes() {
    return this.cityFunctions.map((cityFunction) => cityFunction.serviceTypes).flat();
  }

  constructor(infrastructure) {
    this.id = infrastructure.id;
    this.name = infrastructure.name;
    this.code = infrastructure.code;
    this.cityFunctions = infrastructure.city_functions
      .map((cityFunction) => new CityFunction(this, cityFunction))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}

class CityFunction {
  id = null;
  name = null;
  code = null;
  infrastructure = null;
  serviceTypes = [];

  constructor(infrastructure, cityFunction) {
    this.id = cityFunction.id;
    this.name = cityFunction.name;
    this.code = cityFunction.code;
    this.infrastructure = infrastructure;
    this.serviceTypes = cityFunction.city_service_types
      .map((serviceType) => new ServiceType(this, serviceType))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}

class ServiceType {
  id = null;
  name = null;
  code = null;
  cityFunction = null;
  isBuilding = null;
  publicTransportTime = null;
  walkingRadius = null;

  constructor(cityFunction, serviceType) {
    this.id = serviceType.id;
    this.name = serviceType.name;
    this.code = serviceType.code;
    this.cityFunction = cityFunction;
    this.isBuilding = serviceType.isBuilding;
    this.publicTransportTime = serviceType.public_transport_time_normative;
    this.walkingRadius = serviceType.walking_radius_normative;
  }
}

export default ServiceStore;
