import { makeAutoObservable } from "mobx";

import SubjectStore from "./subjectStore";
import ServiceStore from "./serviceStore";
import SocialStore from "./socialStore";
import AuthStore from "./authStore";
import LayerStore from "./layerStore";
import ProjectStore from "./projectStore";
import AggregationContextStore from "./aggregationContextStore";

class _GlobalStore {
  subjectStore = null;
  socialStore = null;
  serviceStore = null;
  authStore = null;
  layerStore = null;
  projectStore = null;
  aggregationContextStore = null;

  get isFetched() {
    return (
      this.subjectStore.isFetched &&
      this.socialStore.isFetched &&
      this.serviceStore.isFetched &&
      this.layerStore.isInitialized &&
      this.projectStore.isInitialized
    );
  }

  constructor() {
    makeAutoObservable(this);
    this.authStore = new AuthStore(this);
    this.subjectStore = new SubjectStore(this);
    this.projectStore = new ProjectStore(this);
    this.layerStore = new LayerStore(this);
    this.socialStore = new SocialStore(this);
    this.serviceStore = new ServiceStore(this);
    this.contextStore = new AggregationContextStore(this);
  }
}

const GlobalStore = new _GlobalStore();

export default  GlobalStore
