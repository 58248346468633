import {
  action,
  autorun,
  makeAutoObservable,
  runInAction,
  toJS,
  when,
} from "mobx";
import axios from "axios";
import L from "leaflet";

export const divisionTypes = {
  ADMIN_UNIT_PARENT: "Админ. единицы",
  MUNICIPALITY_PARENT: "Округа",
};

class SubjectStore {
  _globalStore = null;
  currentCity = null;
  publicCities = null;
  userCities = null;
  isInitialized = false;
  cityStatistics = null;

  get cities() {
    let cities = [];
    if (this.publicCities && !this.userCities) cities = [...this.publicCities];
    if (this.publicCities && this.userCities)
      cities = [
        ...this.publicCities,
        ...this.userCities.filter(
          (userCity) =>
            !this.publicCities.find(
              (publicCity) => publicCity.id === userCity.id
            )
        ),
      ];
    return cities.sort((a, b) => a.name.localeCompare(b.name));
  }

  get isUserCitiesFetched() {
    return this.userCities !== null;
  }

  get isPublicCitiesFetched() {
    return this.publicCities !== null;
  }

  get isFetched() {
    //available for map view
    return this.currentCity && this.currentCity.isFetched;
  }

  get city() {
    return this.currentCity;
  }

  get regions() {
    let result = {};
    this.cities.forEach((city) => {
      if (city.region) result[city.region] = true;
    });
    return Object.keys(result);
  }

  getCitiesByRegion(region) {
    return this.cities.filter((city) => city.region === region);
  }

  resetCurrentCity() {
    if (this.cities.indexOf(this.currentCity) < 0)
      this.setCity(this.defaultCity);
  }

  setCurrentCity(city) {
    this.setCity(city);
  }

  setCity(city) {
    if (city === this.currentCity) return;
    window.localStorage.setItem("loyalCity.currentCityId", city.id);
    window.localStorage.setItem("localCity.currentCityCode", city.code);
    city.fetchData();
    city.fetchTerritories();
    this.currentCity = city;
  }

  getCityByName(name) {
    let city = this.cities.find((city) => city.name === name);
    return city ? city : null;
  }

  getCityById(id) {
    let city = this.cities.find((city) => city.id === parseInt(id));
    return city ? city : null;
  }

  async fetchUserCities() {
    return axios
      .get(`${process.env.REACT_APP_MAIN_API_NEW}/api/list/cities`, {
        headers: {
          Authorization: this._globalStore.authStore.user.authorizationHeader,
        },
        params: {
          centers_only: true,
        },
      })
      .then(
        action(
          ({ data: cities }) =>
            (this.userCities = cities.map((city) => new City(city, this)))
        )
      );
  }

  async fetchPublicCities() {
    return axios
      .get(`${process.env.REACT_APP_MAIN_API_NEW}/api/list/cities`, {
        params: {
          centers_only: true,
        },
      })
      .then(
        action(
          ({ data: cities }) =>
            (this.publicCities = cities.map((city) => new City(city, this)))
        )
      );
  }

  async fetchStatistics(token) {
    let config = null;
    if (token) {
      config = {
        headers: {
          Authorization: token,
        },
      };
    }
    return axios
      .get(process.env.REACT_APP_CITY_STATISTICS, config)
      .then(action(({ data }) => (this.cityStatistics = data)));
  }

  get defaultCity() {
    return this.publicCities[0];
  }

  constructor(globalStore) {
    makeAutoObservable(this);
    this._globalStore = globalStore;
    //первая инициализация
    when(
      () =>
        globalStore.authStore.isInitialized &&
        (!globalStore.authStore.user || globalStore.authStore.isLogged),
      () => {
        let promises = [this.fetchPublicCities()];
        if (globalStore.authStore.isLogged) {
          promises.push(
            this.fetchUserCities(),
            this.fetchStatistics(globalStore.authStore.user.authorizationHeader)
          );
        } else {
          promises.push(this.fetchStatistics(undefined));
        }
        Promise.allSettled(promises).then(
          action(() => {
            const storageCityId = window.localStorage.getItem(
              "loyalCity.currentCityId"
            );
            if (storageCityId) {
              const storageCity = this.getCityById(storageCityId);
              if (storageCity) this.setCurrentCity(storageCity);
              else this.resetCurrentCity();
            } else this.resetCurrentCity();
            this.isInitialized = true; //flag that everything is ready for the work
          })
        );
      }
    );
    autorun(() => {
      if (!this.isInitialized) return;
      if (!globalStore.authStore.isLogged)
        runInAction(() => {
          //если незалогиненный ресетаем города юзера
          this.userCities = null;
          this.resetCurrentCity();
        });
      else if (!this.isUserCitiesFetched) this.fetchUserCities(); //если залогинен, грузим города
    });
  }
}

class City {
  subjectStore = null;
  id = null;
  code = null;
  name = null;
  population = null;
  center = null;
  divisionType = null;
  _data = null;
  territories = null;
  region = null;

  get geojson() {
    return this._data;
  }

  get isFetched() {
    //geometries and territories fetched
    return (
      this._data &&
      this.territories &&
      // this.territories.length > 0 &&
      this.territories.reduce((b, territory) => territory.isFetched && b, true)
    );
  }

  get divisionTypes() {
    return divisionTypes;
  }

  get administrativeUnits() {
    if (this.divisionType === divisionTypes.ADMIN_UNIT_PARENT)
      return this.parentTerritories;
    else return this.childTerritories;
  }

  get municipalities() {
    if (this.divisionType === divisionTypes.MUNICIPALITY_PARENT)
      return this.parentTerritories;
    else return this.childTerritories;
  }

  get parentTerritories() {
    return this.territories
      ?.slice()
      .sort((a, b) => a.name.localeCompare(b.name));
    // .map(territory => toJS(territory));
  }

  get childTerritories() {
    return this.territories
      .map((territory) => {
        switch (this.divisionType) {
          default:
          case this.divisionTypes.ADMIN_UNIT_PARENT:
            return territory.municipalities;
          case this.divisionTypes.MUNICIPALITY_PARENT:
            return territory.administrativeUnits;
        }
      })
      .flat();
    // .map(territory => toJS(territory));
  }

  get parentTerritoryName() {
    if (this.divisionType === this.divisionTypes.ADMIN_UNIT_PARENT)
      return this.divisionTypes.ADMIN_UNIT_PARENT;
    else return this.divisionTypes.MUNICIPALITY_PARENT;
  }

  get childTerritoryName() {
    if (this.divisionType === this.divisionTypes.ADMIN_UNIT_PARENT)
      return this.divisionTypes.MUNICIPALITY_PARENT;
    else return this.divisionTypes.ADMIN_UNIT_PARENT;
  }

  getParentTerritoryById(id) {
    const territory = this.parentTerritories.find(
      (territory) => territory.id === parseInt(id)
    );
    return territory ? territory : null;
  }

  getChildTerritoryById(id) {
    const territory = this.childTerritories.find(
      (territory) => territory.id === parseInt(id)
    );
    return territory ? territory : null;
  }

  fetchTerritories() {
    if (this.isFetched) return;
    axios
      .get(
        `${process.env.REACT_APP_MAIN_API_NEW}/api/city/${this.name}/territories`,
        {
          headers: {
            Authorization: this.subjectStore._globalStore.authStore.isLogged
              ? this.subjectStore._globalStore.authStore.user
                  .authorizationHeader
              : null,
          },
        }
      )
      .then(
        action(({ data: territories }) => {
          switch (this.divisionType) {
            case this.divisionTypes.ADMIN_UNIT_PARENT:
              this.territories = territories.map(
                (territory) =>
                  new AdministrativeUnit(
                    territory,
                    this.name,
                    null,
                    this.subjectStore
                  )
              );
              break;
            case this.divisionTypes.MUNICIPALITY_PARENT:
              this.territories = territories.map(
                (territory) =>
                  new Municipality(
                    territory,
                    this.name,
                    null,
                    this.subjectStore
                  )
              );
              break;
          }
        })
      );
  }

  fetchData() {
    axios
      .get(
        `${process.env.REACT_APP_MAIN_API_NEW}/api/city/${this.name}/geometry`,
        {
          headers: {
            Authorization: this.subjectStore._globalStore.authStore.isLogged
              ? this.subjectStore._globalStore.authStore.user
                  .authorizationHeader
              : null,
          },
        }
      )
      .then(
        action(
          ({ data: geometry }) =>
            (this._data = {
              type: "Feature",
              geometry: geometry,
              properties: {
                name: this.name,
              },
            })
        )
      );
  }

  constructor(cityJson, subjectStore) {
    makeAutoObservable(this);
    this.id = cityJson.id;
    this.name = cityJson.name;
    this.code = cityJson.code;
    this.population = cityJson.population;
    this.center = L.latLng(
      cityJson.center.coordinates[1],
      cityJson.center.coordinates[0]
    );
    this.divisionType = this.divisionTypes[cityJson.division_type];
    this.subjectStore = subjectStore;
    this.region = cityJson.region ? cityJson.region : "Город";
  }
}

class AdministrativeUnit {
  id = null;
  name = null;
  type = null;
  population = null;
  center = null;
  geometry = null;
  municipalities = null;
  parentTerritory = null;

  get subjectType() {
    return divisionTypes.ADMIN_UNIT_PARENT;
  }

  get metricType() {
    return "district";
  }

  get geojson() {
    return {
      type: "Feature",
      geometry: toJS(this.geometry),
      properties: {
        name: this.name,
      },
    };
  }

  get isFetched() {
    if (this.municipalities === null) {
      return !!this.geometry;
    } else {
      return (
        this.geometry &&
        this.municipalities.reduce(
          (b, municipality) => municipality.isFetched && b,
          true
        )
      );
    }
  }

  get childTerritories() {
    return this.municipalities
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  constructor(
    administrativeUnit,
    cityName,
    parentTerritory = null,
    subjectStore
  ) {
    const fetchGeometry = async () =>
      axios
        .get(
          `${process.env.REACT_APP_MAIN_API_NEW}/api/city/${cityName}/administrative_unit/${administrativeUnit.name}/geometry`,
          {
            headers: {
              Authorization: subjectStore._globalStore.authStore.isLogged
                ? subjectStore._globalStore.authStore.user.authorizationHeader
                : null,
            },
          }
        )
        .then(({ data }) => data);
    makeAutoObservable(this);
    this.parentTerritory = parentTerritory;
    this.id = administrativeUnit.id;
    this.name = administrativeUnit.name;
    this.type = administrativeUnit.type;
    this.population = administrativeUnit.population;
    this.center = L.latLng(
      administrativeUnit.center.coordinates[1],
      administrativeUnit.center.coordinates[0]
    );
    if ("municipalities" in administrativeUnit)
      this.municipalities = administrativeUnit.municipalities.map(
        (municipality) =>
          new Municipality(municipality, cityName, this, subjectStore)
      );
    else this.municipalities = [];
    fetchGeometry().then(action((geometry) => (this.geometry = geometry)));
  }
}

class Municipality {
  id = null;
  name = null;
  type = null;
  population = null;
  center = null;
  geometry = null;
  administrativeUnits = null;
  parentTerritory = null;

  get subjectType() {
    return divisionTypes.MUNICIPALITY_PARENT;
  }

  get metricType() {
    return "mo";
  }

  get geojson() {
    return {
      type: "Feature",
      geometry: toJS(this.geometry),
      properties: {
        name: this.name,
      },
    };
  }

  get childTerritories() {
    return this.administrativeUnits
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get isFetched() {
    if (this.administrativeUnits === null) {
      return !!this.geometry;
    } else {
      return (
        !!this.geometry &&
        this.administrativeUnits.reduce(
          (b, municipality) => municipality.isFetched && b,
          true
        )
      );
    }
  }

  constructor(municipality, cityName, parentTerritory = null, subjectStore) {
    const fetchGeometry = async () =>
      axios
        .get(
          `${process.env.REACT_APP_MAIN_API_NEW}/api/city/${cityName}/municipality/${municipality.name}/geometry`,
          {
            headers: {
              Authorization: subjectStore._globalStore.authStore.isLogged
                ? subjectStore._globalStore.authStore.user.authorizationHeader
                : null,
            },
          }
        )
        .then(({ data }) => data);
    makeAutoObservable(this);
    this.parentTerritory = parentTerritory;
    this.id = municipality.id;
    this.name = municipality.name;
    this.type = municipality.type;
    this.population = municipality.population;
    this.center = L.latLng(
      municipality.center.coordinates[1],
      municipality.center.coordinates[0]
    );
    if ("administrative_units" in municipality)
      this.administrativeUnits = municipality.administrative_units.map(
        (administrativeUnit) =>
          new AdministrativeUnit(
            administrativeUnit,
            cityName,
            this,
            subjectStore
          )
      );
    else this.administrativeUnits = [];
    fetchGeometry().then(action((geometry) => (this.geometry = geometry)));
  }
}

export default SubjectStore;
